﻿export function initializeCustomScrollbar() {
  const selector = '.card-slider-container';
  const cardSlider = document.querySelector(`${selector} .card-slider`);
  const customScrollbarThumb = document.querySelector(`${selector} .custom-scrollbar-thumb`);
  const scrollBtn = document.querySelector(`${selector} .scroll-btn`);

  if (!cardSlider || !customScrollbarThumb || !scrollBtn) {
    console.warn('Custom scrollbar initialization failed: elements not found.');
    return;
  }

  // Function to update custom scrollbar thumb position based on slider scroll
  function updateScrollbarThumbPosition() {
    const scrollPercentage = cardSlider.scrollLeft / (cardSlider.scrollWidth - cardSlider.clientWidth);
    const thumbWidthPercentage = (cardSlider.clientWidth / cardSlider.scrollWidth) * 100;
    customScrollbarThumb.style.width = `${thumbWidthPercentage}%`;
    customScrollbarThumb.style.left = `${scrollPercentage * (100 - thumbWidthPercentage)}%`;
  }

  // Calculate scroll distance as one card width plus its margin
  const card = cardSlider.querySelector('.card');
  const cardStyle = getComputedStyle(card);
  const cardMarginRight = parseFloat(cardStyle.marginRight);
  const scrollDistance = card.offsetWidth + cardMarginRight;

  scrollBtn.addEventListener('click', () => {
    cardSlider.scrollBy({ left: scrollDistance, behavior: 'smooth' });
    // Update the scrollbar thumb position after scrolling
    setTimeout(updateScrollbarThumbPosition, 250); // Adjust timeout to match scroll behavior timing
  });

  // Initialize scrollbar thumb position
  updateScrollbarThumbPosition();

  // Listen for manual scroll and update thumb position
  cardSlider.addEventListener('scroll', updateScrollbarThumbPosition);

  // Dragging functionality
  let isDragging = false;
  const dragStart = (e) => {
    isDragging = true;
    const initialClickX = e.clientX;
    const initialThumbLeft = customScrollbarThumb.offsetLeft;

    const dragMove = (e) => {
      if (isDragging) {
        const deltaX = e.clientX - initialClickX;
        let newLeft = initialThumbLeft + deltaX;
        const maxLeft = customScrollbarThumb.parentNode.offsetWidth - customScrollbarThumb.offsetWidth;

        newLeft = Math.max(0, Math.min(newLeft, maxLeft));
        customScrollbarThumb.style.left = `${newLeft}px`;

        const scrollPercentage = newLeft / maxLeft;
        const maxScroll = cardSlider.scrollWidth - cardSlider.clientWidth;
        cardSlider.scrollLeft = maxScroll * scrollPercentage;
      }
    };

    const dragEnd = () => {
      document.removeEventListener('mousemove', dragMove);
      document.removeEventListener('mouseup', dragEnd);
      isDragging = false;
    };

    document.addEventListener('mousemove', dragMove);
    document.addEventListener('mouseup', dragEnd);
  };

  customScrollbarThumb.addEventListener('mousedown', dragStart);
}