﻿//Load Google services
import { initializeGoogleServices } from "./packages/BasePackage/loaders/google-services.js";
initializeGoogleServices();


import { initializeHeader } from "./custom/modules/header-module.js";
initializeHeader();

import { initializeCustomScrollbar } from "./custom/modules/header-homepage-module.js";
document.addEventListener('DOMContentLoaded', () => {
  initializeCustomScrollbar();
});
