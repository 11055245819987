﻿/**
 * Service which accesses application wide settings which values comes from Umbraco
 * */ 
export let umbracoSettingsService = (() => {
	let instance;
	const dataAttributeName = "data-global-settings";

	function init() {
		//Get global Umbraco settings from a data attribute
		const elementWithGlobalSettings = document.querySelector(`*[${dataAttributeName}]`);
		const settingsData = elementWithGlobalSettings ? JSON.parse(elementWithGlobalSettings.getAttribute(dataAttributeName)) : {};
    this.settings = settingsData;
	}
 
	return function() {
		if ( !instance ) {
			instance = new init();
		}
		return instance;
	};
})();