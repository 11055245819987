﻿/**
 * Loader which initializes Google services based on the settings of Umbraco
 */
import { umbracoSettingsService } from "../services/umbraco-settings-service.js"
const settingsManager = umbracoSettingsService();

/**
 * Initializes the Google services
 */
export function initializeGoogleServices() {
  
  //If the tag manager is active and the container id is set, run the script
  if (settingsManager.settings.isTagmanagerActive === true && settingsManager.settings.tagManagerContainerId !== "") {
    console.log("Google tag manager is active");
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start':
          new Date().getTime(),
        event: 'gtm.js'
      });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', settingsManager.settings.tagManagerContainerId);
  }
  else {
    console.log("Google tag manager is NOT active");
  }
}

